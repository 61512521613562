.toolbarLogo {
  margin-left: 1rem;
  margin-top: 8px;
}

@media only screen and (max-width: 350px) {
  .toolbarLogo {
    margin-left: 0.5rem;
  }
}
