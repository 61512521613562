.link {
  color: #333;
  text-decoration: none;
}

.sideDrawer {
  height: 100%;
  background-color: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  max-width: 350px;
  overflow-y: auto;
  width: 60%;
  z-index: 700;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.sideDrawerFastClose {
  transition: unset;
}

.sideDrawer.open {
  transform: translateX(0);
}

.sideDrawerMainItem .link {
  margin: 1rem 0;
  background: #f5f5f5;
  border-radius: 8px;
  height: 55px;
  display: flex;
  align-items: center;
  width: 90%;
}

.sideDrawer ul {
  list-style: none;
  margin-top: 32px;
  padding-left: 12px;
  margin-bottom: 35px;
}

.spacer {
  flex: 1;
}

.sideDrawerMainItemTitle {
  margin-left: 12px;
}

.sideDrawerMainItemMainTitle,
.sideDrawerMainItemSubTitle {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sideDrawerMainItemMainTitle {
  color: var(--brownish-grey);
}

.sideDrawerMainItemSubTitle {
  font-weight: 900;
  color: var(--black);
}

.sideDrawerSubItemLabel {
  height: 30px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: italic;
  line-height: 2.11;
  letter-spacing: normal;
  color: var(--brownish-grey);
}

.sideDrawerSubItemWrapper {
  margin-left: 12px;
}

.sideDrawerSubItem {
  display: flex;
  align-items: center;
  width: 90%;
  cursor: pointer;
}

.line {
  width: 90%;
  margin: 13px 51px 12px 0;
  border: solid 1px #e7e7e7;
}

.mainLine {
  width: 87%;
  margin: 13px 51px 12px 12px;
  border: solid 1px #e7e7e7;
}

.privacy {
  bottom: 6%;
}

.sideDrawerWrapper {
  padding-left: 14px;
}

.sideDrawerHeader {
  display: flex;
  align-items: center;
}
.sideDrawerClose {
  cursor: pointer;
  margin-right: 2rem;
}

.toolbarSignOutButton,
.toolbarSignInButton {
  display: none;
  align-items: center;
  font-family: Lato;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  border-radius: 2px;
  border: none;
  background-color: white;
  cursor: pointer;
  margin-left: 17px;
  margin-top: -12px;
}

.toolbarSignOutArrowIcon,
.toolbarSignInArrowIcon {
  margin-left: 5px;
}

.toolbarSignInArrowIcon {
  margin-top: 12px;
}

.toolbarSignInLabel {
  padding-left: 5px;
}

.dropdownContent {
  display: block;
  font-family: Lato;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  font-size: 14px;
  border-radius: 2px;
  border: none;
  width: 90%;
  background-color: #f5f5f5;
  cursor: pointer;
  padding: 20px 20px 20px 22px;
  margin-left: -14px;
}

.toolbarSignOutLabel {
  margin-left: 5px;
  display: inline;
}

.toolbarSignOutButton {
  padding: unset;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}
.toolbarSignInButtonWrapper {
  width: 100px;
}

.logoutIcon,
.toolbarSignOutIcon {
  display: inline;
  vertical-align: middle;
}
.logoutIcon {
  margin-left: 11px;
}

.signoutSeparatorLine {
  background-color: #d7d7d7;
  margin: 0;
  margin-left: -14px;
}
.toolbarSignOutWrapper {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .sideDrawerClose {
    margin-top: 10px;
  }
  .toolbarLogo {
    display: none;
  }
  .toolbarSignOutButton,
  .toolbarSignInButton {
    display: flex;
  }
  .toolbarSignInLabel {
    font-size: 14px;
  }
  .sideDrawer {
    width: 70%;
  }
  .sideDrawer ul {
    margin-top: 20px;
  }
  .toolbarSignOutWrapper {
    display: unset;
  }
}

@media only screen and (max-width: 350px) {
  .sideDrawerClose {
    margin-right: 1rem;
    margin-top: 10px;
  }
}
