.placeholderItem {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.placeholderItem::before {
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  content: '';
  display: block;
  height: 100%;
  left: -150px;
  position: absolute;
  top: 0;
  width: 150px;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
