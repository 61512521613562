.link {
  color: #303030;
  text-decoration: none;
}

.links {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.separator {
  margin: 0 1rem;
}

.wrapper {
  font-family: Lato;
  font-size: 14px;
  font-stretch: normal;
  font-style: italic;
  font-weight: 900;
  letter-spacing: normal;
  line-height: 2.11;
  margin: 100% 10px 0;
  text-align: center;
}
