.toggleButton {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  color: #303030;
  font-size: 24px;
}

.toggleButton:focus {
  outline: none;
}
