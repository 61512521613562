.arrow {
  color: #b0b0b0;
  font-size: 24px;
  margin: 0 1rem;
}

.center {
  text-align: center;
}

.column.ulLinks {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0 5rem 0 0;
  width: 100%;
}

.downloadApp {
  margin-right: 10px;
  text-align: center;
}

.icon {
  height: 34px;
  margin-right: 0.4rem;
  width: 34px;
}

.left {
  min-width: 50%;
}

.linkDot {
  margin: 0 1rem;
}

.links {
  margin-bottom: 1rem;
  color: #606060;
}

.linkLi {
  color: #606060;
  display: inline-block;
  text-decoration: none;
}

.linksWrapper {
  flex-flow: column nowrap;
}

.linksWrapper.desktopOnly {
  display: none;
}

.linksWrapper.mobileOnly {
  display: flex;
}

.links a,
.links strong {
  color: #606060;
  display: block;
}

.padding {
  padding: 1rem 1rem 0.25rem;
  width: calc(100% - 2rem);
}

.playIcon {
  width: 128px;
}

.rows {
  align-items: flex-start;
  display: flex;
  flex-flow: row;
}

.rows.desktopBottomRow {
  align-items: center;
  background-color: #f0f0f0;
  flex-flow: column nowrap;
  font-size: 16px;
  margin-top: 3rem;
  padding: 0 10px;
}

.rows.desktopBottomRow .column {
  padding: 0;
  margin-bottom: 1rem;
}

.rows.desktopBottomRow .linksWrapper {
  align-items: center;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: 1rem;
}

.rows.desktopBottomRow .playIcon {
  margin: auto;
  text-align: center;
}

.social {
  align-items: center;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: #333;
  display: inline-flex;
  font-size: 10px;
  justify-content: space-between;
  margin: 1rem 0 0;
  text-decoration: none;
}

.socialText {
  color: #808080;
}

.socialContent {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.socialWrapper {
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.text {
  color: #606060;
  width: 80%;
}

.wrapper {
  background-color: #f5f5f5;
  font-size: 12px;
}

@media only screen and (min-width: 1024px) {
  .arrow {
    margin: 0 1rem;
  }

  .column.ulLinks {
    display: flex nowrap;
    justify-content: space-between;
    margin-right: 2rem;
    width: 48%;
  }

  .downloadApp {
    font-size: 14px;
  }

  .icon {
    height: 51px;
    margin-right: 1rem;
    width: 51px;
  }

  .linksWrapper {
    align-items: center;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .linksWrapper.desktopOnly {
    display: flex;
  }

  .linksWrapper.mobileOnly {
    display: none;
  }

  .padding {
    padding: 2rem 0 0.5rem 0;
  }

  .rows.desktopBottomRow {
    background-color: unset;
    flex-flow: row nowrap;
    margin-top: 1rem;
  }

  .social {
    font-size: unset;
  }

  .social:first-of-type {
    margin-right: 1rem;
  }

  .socialWrapper {
    align-items: center;
    flex-flow: row nowrap;
  }

  .wrapper {
    margin-left: -80px;
    padding: 0 80px;
    width: 100%;
  }
}
