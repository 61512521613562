.languageOption {
  align-items: center;
  background-color: #f5f5f5;
  color: #333;
  border-radius: 2px;
  display: flex;
  height: 60px;
  margin: 0 8px 8px 0;
  padding: 17px 65px 18px 17px;
  text-decoration: none;
  width: 140px;
}

.languageOption span {
  width: 31px;
  height: 25px;
  margin: 0 0 0 9px;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
}

.languageOptionRadio {
  width: 18px;
  height: 18px;
  margin: 3px 9px 4px 0;
  cursor: pointer;
  background-color: #fff;
}

.languageSelectModalTitle {
  height: 22px;
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  clear: both;
}
.languageSelectModalWrapper {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
}

.languageOptionClose {
  float: 'right';
  background: 'none';
  border: 'none';
  cursor: 'pointer';
}

@media only screen and (max-width: 700px) {
  .languageOption {
    padding: 0 0 0 5px;
    box-sizing: border-box;
  }
}
