.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  height: 56px;
  z-index: 500;
}

.toolbarNavigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
}

.spacer {
  flex: 1;
}

.toolbarLanguageButton {
  height: 36px;
  font-family: Lato;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  border-radius: 2px;
  border: none;
  background-color: #f5f5f5;
  cursor: pointer;
}

.toolbarLanguageSelect label {
  width: 51px;
  height: 12px;
  margin: 0 5px 0 0;
  font-family: Lato;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--black);
}

.toolbarLanguageSelectIcon {
  width: 7px;
  height: 4px;
  margin: 5px 0 3px 5px;
  border: solid 1.5px var(--black);
}

.toolbarSignOutButton,
.toolbarSignInButton {
  align-items: center;
  background-color: white;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  font-family: Lato;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  justify-content: center;
}

.toolbarMyOrderLabel,
.toolbarSignInIcon,
.toolbarSignOutIcon,
.toolbarSignInLabel,
.toolbarSignOutLabel {
  display: inline;
  vertical-align: middle;
}

.dropdownContent {
  display: none;
  position: absolute;
  font-family: Lato;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  font-size: 14px;
  border-radius: 2px;
  border: none;
  background-color: white;
  cursor: pointer;
  z-index: 1;
  /* margin-top: 10px; */
  padding-left: 10px;
  padding: 20px 20px 20px 22px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-top: 1px solid #e7e7e7;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 208px;
}

.logoutIcon {
  vertical-align: middle;
  display: inline;
}

.toolbarSignOutLabel {
  margin-left: 5px;
}

.toolbarSignOutButton {
  padding: unset;
  padding-left: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}
.dropdown:hover .dropdownContent {
  display: block;
}
.toolbarSignInButtonWrapper {
  width: 100px;
}

.myOrderIconWrapper {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 14px;
  font-weight: 900;
  margin-right: 10px;
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .toolbarSignInButtonWrapper {
    display: none;
  }
  .dropdown {
    display: none;
  }
  .toolbarMyOrderLabel {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .toolbarNavigation {
    margin: 0 200px;
    padding: 0;
  }

  .toolbarLanguageButton {
    width: 120px;
  }
}
